/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import "../styles/globals.scss";
import jsonld from "util/jsonld";
import App from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import { useApollo } from "init/apolloClient";
import { ApolloProvider } from "@apollo/client";
import { getCookie, setServerIdCookie } from "util/cookies";
import type { AppProps, NextWebVitalsMetric, AppContext } from "next/app";
import { ToastContextProvider } from "context/ToastContext";
import MobileContext from "context/MobileContext";

import CurrentUserProvider from "components/CurrentUserProvider";
import {
  BASE_URL,
  SITE_NAME,
  FB_APP_ID,
  SM_TWITTER,
  THEME_COLOR,
  SM_FACEBOOK,
  SM_LINKEDIN,
  CONTACT_ZIP,
  CONTACT_CITY,
  CONTACT_STATE,
  CONTACT_ADDRESS,
  SITE_DESCRIPTION,
  NEXT_PUBLIC_FOO_ACCOUNT_ID,
  NEXT_PUBLIC_FOO_VITALS_URL,
} from "../config";

function MiyagiNext({ Component, pageProps, router }: AppProps) {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const serverIdValue = getCookie("SERVERID");

    if (serverIdValue !== "miyagi-next") setServerIdCookie("miyagi-next");

    router.events.on("routeChangeComplete", (url) => {
      window.analytics.page(url);
    });

    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>SquareFoot</title>
        <meta name="description" content={SITE_DESCRIPTION} />
        <meta name="og:site_name" content={SITE_NAME} />
        <meta name="application-name" content={SITE_NAME} />
        <meta name="apple-mobile-web-app-title" content={SITE_NAME} />
        <meta name="theme_color" content={THEME_COLOR} />
        <meta name="fb:app_id" content={FB_APP_ID} />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify([
              jsonld("WebSite", {
                name: SITE_NAME,
                url: BASE_URL,
              }),
              jsonld("Organization", {
                name: SITE_NAME,
                url: BASE_URL,
                logo: "https://www.squarefoot.com/apple-touch-icon.png",
                sameAs: [SM_FACEBOOK, SM_TWITTER, SM_LINKEDIN],
                location: jsonld("Place", {
                  address: jsonld("PostalAddress", {
                    streetAddress: CONTACT_ADDRESS,
                    addressLocality: CONTACT_CITY,
                    addressRegion: CONTACT_STATE,
                    postalCode: CONTACT_ZIP,
                  }),
                }),
              }),
            ]),
          }}
        />
        {/* <script key="segment" async>
          {useInvasiveSegmentSnippet(true)}
        </script> */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#212121" />
        <link rel="preconnect" href="https://d2uvg1ukogcips.cloudfront.net" />
      </Head>
      <ToastContextProvider>
        <MobileContext.Provider value={pageProps.isMobileView}>
          <ApolloProvider client={apolloClient}>
            <CurrentUserProvider>
              <Component {...pageProps} />
            </CurrentUserProvider>
          </ApolloProvider>
        </MobileContext.Provider>
      </ToastContextProvider>
      {/* <div className={cn("ecs_task_identifier", styles.ecs_task_identifier)}>
        {`ECS_TASK_${ecs_task}`}
      </div> */}
      <script
        type="text/javascript"
        async
        src="https://www.googleadservices.com/pagead/conversion_async.js"
      />
    </>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === "web-vital") {
    const { name, value } = metric;
    const body = JSON.stringify({
      accountId: NEXT_PUBLIC_FOO_ACCOUNT_ID,
      name,
      value,
      url: window.location.href,
    });

    return (
      (navigator.sendBeacon &&
        navigator.sendBeacon(NEXT_PUBLIC_FOO_VITALS_URL, body)) ||
      fetch(NEXT_PUBLIC_FOO_VITALS_URL, {
        body,
        method: "POST",
        keepalive: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
  }
  return null;
}

MiyagiNext.getInitialProps = async (appContext: AppContext) => {
  const traceId = appContext.ctx.req?.headers?.["x-amzn-trace-id"];
  const { pageProps } = await App.getInitialProps(appContext);
  const isMobileView = (
    appContext.ctx.req
      ? appContext.ctx?.req?.headers["user-agent"] || ""
      : navigator.userAgent
  ).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);

  return {
    pageProps: {
      traceId,
      isMobileView, // this is determined during ssr so it more reliable than isTouch() or css checking
      ...pageProps,
    },
  };
};

export default MiyagiNext;
